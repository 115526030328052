// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting_field input {
    border: none;
    outline: none;
}
.setting_field label {
    font-weight: 500;
}
.radio_btn label {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}
.radio_btn img{
    max-width: 100%;
}
.radio_btn input[type="radio"] {
    display: none;
}

.radio_btn .radio_box {
    display: inline-flex;
    width: 25px;
    height: 25px;
    border: 2px solid #000;
    align-items: center;
    justify-content: center;
    font-size: 0;
}



.radio_btn input:checked ~ label .radio_box {
    font-size: 20px;
}
.radio_btn.radio_img_btn label {
    display: grid;
    justify-items: center;
}
.setting_radio {
    display: flex;
    align-items: center;
    gap: 30px;
}
.plus_icon{
    color: black;
}
.minus_icon{
    color: black;
}

.radio_btn {
    display: flex;
    gap: 11px;
    align-items: center;
    flex-direction: row-reverse;
}`, "",{"version":3,"sources":["webpack://./src/Setting/settings.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;;;AAIA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,2BAA2B;AAC/B","sourcesContent":[".setting_field input {\r\n    border: none;\r\n    outline: none;\r\n}\r\n.setting_field label {\r\n    font-weight: 500;\r\n}\r\n.radio_btn label {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 10px;\r\n    position: relative;\r\n}\r\n.radio_btn img{\r\n    max-width: 100%;\r\n}\r\n.radio_btn input[type=\"radio\"] {\r\n    display: none;\r\n}\r\n\r\n.radio_btn .radio_box {\r\n    display: inline-flex;\r\n    width: 25px;\r\n    height: 25px;\r\n    border: 2px solid #000;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-size: 0;\r\n}\r\n\r\n\r\n\r\n.radio_btn input:checked ~ label .radio_box {\r\n    font-size: 20px;\r\n}\r\n.radio_btn.radio_img_btn label {\r\n    display: grid;\r\n    justify-items: center;\r\n}\r\n.setting_radio {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 30px;\r\n}\r\n.plus_icon{\r\n    color: black;\r\n}\r\n.minus_icon{\r\n    color: black;\r\n}\r\n\r\n.radio_btn {\r\n    display: flex;\r\n    gap: 11px;\r\n    align-items: center;\r\n    flex-direction: row-reverse;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
