// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast-container--top-center {
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 100% !important;
    max-width: 350px !important;
}

.Toastify__toast-container--top-center .Toastify__toast-body {
    padding: 30px;
    font-weight: 600;
    color:white;
}
.Toastify__toast-theme--dark{
    background: #ffe9e9 !important;
}
.Toastify__toast-container--top-center .Toastify__toast-body{
    font-size: 25px !important;
    color: #000 !important;
}`, "",{"version":3,"sources":["webpack://./src/main component/toaster.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gCAAgC;IAChC,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,8BAA8B;AAClC;AACA;IACI,0BAA0B;IAC1B,sBAAsB;AAC1B","sourcesContent":[".Toastify__toast-container--top-center {\r\n    top: 50% !important;\r\n    transform: translate(-50%, -50%);\r\n    width: 100% !important;\r\n    max-width: 350px !important;\r\n}\r\n\r\n.Toastify__toast-container--top-center .Toastify__toast-body {\r\n    padding: 30px;\r\n    font-weight: 600;\r\n    color:white;\r\n}\r\n.Toastify__toast-theme--dark{\r\n    background: #ffe9e9 !important;\r\n}\r\n.Toastify__toast-container--top-center .Toastify__toast-body{\r\n    font-size: 25px !important;\r\n    color: #000 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
