import React from "react";
import { Navbar } from "./Layout/Navbar";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();

  console.log('location.pathname', location.pathname)
  return (
    <div>
      <Navbar />
      <main
        className={`layout ${
          (location.pathname === "/evaluation" ||
          location.pathname === "/training") && "main_battle-wrapper"
        }`}
      >
        {children}
      </main>
    </div>
  );
};

export default Layout;
