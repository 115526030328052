import React, { useEffect, useState } from "react";
import { Alert, Spinner  } from "react-bootstrap";
import { useNavigate, useLocation  } from "react-router-dom";

const URL=  process.env.REACT_APP_API_URL; //"http://localhost:5000"

const OrderConfirmation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const userData =(JSON.parse( localStorage.getItem("user")))
  const userId = userData ? userData._id: null;

  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('thrivecart[order_id]');

    const [orderData, setOrderData] = useState(null);

    useEffect(() => {
        const fetchOrderStatus = async () => {
          try {
            const response = await fetch(
              `${URL}/api/orderStatus?orderId=${orderId}&userId=${userId}`
            );
            if (response.ok) {
              setIsLoading(false);
              setIsSuccess(true);
              setTimeout(() => {
                navigate("/");
              }, 4000);
            } else {
              setIsLoading(false);
              setIsSuccess(false);
            }
          } catch (error) {
            console.error("Error fetching order status:", error);
            setIsLoading(false);
            setIsSuccess(false);
          }
        };
    
        fetchOrderStatus();
      }, [orderId, userId, navigate]);


  return (
    
    <div className="d-flex justify-content-center align-items-center vh-100 bg-dark text-light">
      <div className="text-center">
        <h1>Confirmation de Commande</h1>
        {isLoading ? (
          <p>Veuillez patienter...</p>
        ) : isSuccess ? (
          <Alert variant="success" className="my-5">
            <Alert.Heading>Commande Réussie !</Alert.Heading>
            <p>
              Votre commande a été passée avec succès. Merci de faire vos achats chez nous !
            </p>
          </Alert>
        ) : (
          <Alert variant="danger" className="my-5">
            <Alert.Heading>Échec de la Commande !</Alert.Heading>
            <p>Une erreur s'est produite lors du traitement de votre commande. Veuillez réessayer plus tard.</p>
          </Alert>
        )}
        <p className="mt-5">
          Vous serez redirigé vers la page d'accueil sous peu. Veuillez patienter...
        </p>
      </div>
    </div>
  );
};

export default OrderConfirmation;
