// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast {
    display: flex;
    justify-content: space-between;
}
button.Toastify__close-button {
    width: auto;
}
.Toastify__close-button:hover{
    background: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/contact-toast.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".Toastify__toast {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\nbutton.Toastify__close-button {\r\n    width: auto;\r\n}\r\n.Toastify__close-button:hover{\r\n    background: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
